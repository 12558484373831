









































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoyaltyInput from '../../components/LoyaltyInput/index.vue'
import LoyaltyDropdown from '../../components/LoyaltyDropdown/index.vue'
import LoyaltyCheckbox from '../../components/LoyaltyCheckbox/index.vue'
import LoyaltyDatePicker from '../../components/LoyaltyDatePicker/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import dayjs from 'dayjs'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { Dropdown, EnumLoyaltiProgramChannel, EnumStatusFormula } from '@/app/infrastructures/misc/Constants/loyaltyProgram'
import {
  required,
  minValue,
  minLength,
  ValidationRule,
} from 'vuelidate/lib/validators'
import controller, {
  LevelInfoPayload,
  MembershipFormulaPayload,
} from '@/app/ui/controllers/MembershipFormulaController'
import {
  MembershipFormula,
  MultiplierLevel,
} from '@/domain/entities/MembershipFormula'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'

interface IForm {
  channel: Dropdown
  nominal: number
  userType: Array<string>
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  multiplier: Array<Dropdown>
}

interface ValidationsInterface {
  form: {
    channel: {
      required: () => ValidationRule
    },
    nominal: {
      required: () => ValidationRule,
      minValue: ValidationRule;
    },
    userType: {
      required: () => ValidationRule,
      maxLength: ValidationRule,
    },
    startDate: {
      required: () => ValidationRule,
    },
    startTime: {
      required: () => ValidationRule,
    },
    endDate: {
      required: () => ValidationRule,
    },
    endTime: {
      required: () => ValidationRule,
    },
    multiplier: { 
      $each: { 
        value: { 
          required: () => ValidationRule,
        }
      }
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    LoyaltyInput,
    LoyaltyDropdown,
    LoyaltyCheckbox,
    LoyaltyDatePicker,
    Button,
    LoadingOverlay,
  },
})
export default class CreateNewFormula extends Vue {
  controller = controller
  multiplierCount = 0
  isStartDateDisable = false
  errorEndDate = false
  errorEndTime = false
  initialStartDate = ''
  initialStartTime = ''

  tomorrowDate = dayjs()
    .add(1, 'days')
    .format('YYYY-MM-DD')

  channel: Dropdown[] = [
    {
      label: EnumLoyaltiProgramChannel.CUSTOMER_APPS,
      value: EnumLoyaltiProgramChannel.CUSTOMER_APPS,
    },
    {
      label: EnumLoyaltiProgramChannel.MARKETPLACE,
      value: EnumLoyaltiProgramChannel.MARKETPLACE,
    },
    {
      label: EnumLoyaltiProgramChannel.RETAIL,
      value: EnumLoyaltiProgramChannel.RETAIL,
    },
    {
      label: EnumLoyaltiProgramChannel.NEW_COD,
      value: EnumLoyaltiProgramChannel.NEW_COD
    }
  ]

  response: Array<Record<string, string | number>> = []

  userType: Record<string, boolean> = {
    sender: false,
    receiver: false,
  }

  form: IForm = {
    channel: this.channel[-1],
    nominal: NaN,
    userType: [],
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    multiplier: [],
  }

  get isMultiplierNotFilled(): boolean {
    let filledCount = 0

    this.form.multiplier.forEach((item: Dropdown): void => {
      if (item.value) {
        filledCount++
      }
    })

    return filledCount !== this.form.multiplier.length
  }

  created(): void {
    this.fetchMultiplierLevel()
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        channel: { required },
        nominal: {
          required,
          minValue: minValue(1),
        },
        userType: {
          required,
          maxLength: minLength(1),
        },
        startDate: { required },
        startTime: { required },
        endDate: { required },
        endTime: { required },
        multiplier: {
          $each: {
            value: {
              required,
            },
          },
        },
      },
    }
  }

  private goToBack(): void {
    this.$router.push('/loyalty-program/membership-poin-formula')
  }

  private onClickCheckbox(name: string): void {
    if ((<string[]>this.form.userType).includes(name)) {
      this.form.userType = (<string[]>this.form.userType).filter(
        item => item !== name
      )
      this.userType[name] = false
    } else {
      (<string[]>this.form.userType).push(name)
      this.userType[name] = true
    }
  }

  private onSubmit(): void {
    let levelInfo: Array<LevelInfoPayload> = []

    this.form.multiplier.forEach((mp): void => {
      let id = NaN
      const resItem = this.response.find(item => item.label === mp.label)

      if (resItem) {
        id = <number>resItem.value
      }

      levelInfo.push({
        id: id,
        multiplier: Number(mp.value),
      })
    })

    const payload: MembershipFormulaPayload = {
      id: this.$route.params.id,
      channel: this.form.channel.value,
      nominal: this.form.nominal,
      isSender: this.form.userType.includes('sender'),
      isReceiver: this.form.userType.includes('receiver'),
      levelInfo: levelInfo,
      startDate: `${this.form.startDate}T${this.form.startTime}${dayjs().format('Z')}`,
      endDate: `${this.form.endDate}T${this.form.endTime}${dayjs().format('Z')}`,
    }

    controller.update(payload)
  }

  get minimumEndDate(): string {
    const startDate: string = <string>this.form.startDate
    if (!startDate) {
      return this.tomorrowDate
    } else {
      return dayjs(startDate)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    }
  }

  private fetchMembershipFormula(): void {
    controller.getDetail({
      membership_point_formula_id: this.$route.params.id,
    })
  }

  private fetchMultiplierLevel(): void {
    controller.getMultiplierLevel()
  }

  @Watch('controller.dataMembershipFormula')
  setDataMembershipFormula(data: MembershipFormula): void {
    let userType: Array<string> = []
    let multiplierForm: Array<Dropdown> = []

    if (data.isSender) userType.push('sender')
    if (data.isReceiver) userType.push('receiver')

    if (data.levelInfo && JSON.parse(data.levelInfo).length !== 0) {
      const levelInfo = JSON.parse(data.levelInfo)
      this.multiplierCount = levelInfo.length

      levelInfo.forEach((item: Record<string, number>): void => {
        const field = this.response.find(field => field.value === item.id)
        if (field) {
          multiplierForm.push({
            label: <string>field.label,
            value: String(item.multiplier),
          })
        }
      })
    }

    this.form = {
      channel:
        this.channel.find((item): boolean => item.value === data.channel) ||
        this.channel[-1],
      nominal: data.nominal ? data.nominal : NaN,
      userType: userType,
      startDate: data.startDate
        ? Utils.formatDate(String(data.startDate), 'YYYY-MM-DD')
        : '',
      startTime: data.startDate
        ? Utils.formatDate(String(data.startDate), 'HH:mm:ss')
        : '',
      endDate: data.endDate
        ? Utils.formatDate(String(data.endDate), 'YYYY-MM-DD')
        : '',
      endTime: data.endDate
        ? Utils.formatDate(String(data.endDate), 'HH:mm:ss')
        : '',
      multiplier: multiplierForm,
    }

    this.form.userType.forEach((name): void => {
      this.userType[name] = true
    })

    if(data.status === EnumStatusFormula.RUNNING) {
      this.isStartDateDisable = true
    }

    this.initialStartDate = this.form.startDate
    this.initialStartTime = this.form.startTime
  }

  @Watch('form.startDate')
  onChangeStartDate(value: string): void {
    if(value !== this.initialStartDate) {
        this.errorEndDate = true
        this.errorEndTime = true
        this.form.endDate = ''
        this.form.endTime = ''
    }
  }

  @Watch('form.startTime')
  onChangeStartTime(value: string): void {
    if(value !== this.initialStartTime) {
        this.errorEndDate = true
        this.errorEndTime = true
        this.form.endDate = ''
        this.form.endTime = ''
    }
  }

  @Watch('form.endDate')
  onChangeEndDate():void {
    if(this.form.endDate) this.errorEndDate = false
  }

  @Watch('form.endTime')
  onChangeEndTime(): void {
    if(this.form.endTime) this.errorEndTime = false
  }

  @Watch('controller.listMultiplierLevel.data')
  setDataMultiplierLevel(data: MultiplierLevel[]): void {
    if (data) {
      this.response = [
        ...data.map((item: MultiplierLevel): {
          label: string
          value: string | number
        } => {
          return {
            label: item.levelName || '',
            value: item.levelId || '',
          }
        }),
      ]
    }

    this.fetchMembershipFormula()
  }

  @Watch('controller.errUpdate')
  onErrorUpdate(value: string): void {
    if(
      value !== '' &&
      value === EventBusConstants.UPDATE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA) {
        this.$router.push('/loyalty-program/membership-poin-formula')
        this.controller.setErrUpdate('')
      }
  }
}
